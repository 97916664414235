// import Vue from "vue"
import { createRouter,createWebHistory } from "vue-router"
// Vue.use(Router);
const routerHistory=createWebHistory()
// const routerHistory=createWebHashHistory()
const router= createRouter({
    history:routerHistory,
    routes:[
        {
            path:"/previewPdf",
            name:"previewPdf",
            component:()=>import ("../pages/previewPdf/index.vue"),
        },
        {
            path:"/choosePdfPage",
            name:"choosePdfPage",
            component:()=>import ("../pages/choosePdfPage/index.vue")
        },
        {
            path:"/thirdPartyPlatform",
            name:"thirdPartyPlatform",
            component:()=>import ("../pages/thirdPartyPlatform/index.vue")
        },
        {
            path:"/uploadLocalFile",
            name:"uploadLocalFile",
            component:()=>import ("../pages/uploadLocalFile/index.vue")
        }
    ]
})
export default router